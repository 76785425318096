import React, { useEffect } from "react";
import "./Right.scss";
import logo from "../../assets/agent.jpg";
import logo1 from "../../assets/google.png";
import logo2 from "../../assets/yelp.png";
import logo3 from "../../assets/trustpilot-logo.png";
import FamilyCareImg from "../../assets/Family-Care-Clinic.jpg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Right = ({ state, city }) => {
  useEffect(() => {
    const carousel = document.querySelector(".owl-carousel");
    if (carousel) {
      Array.from(carousel.childNodes).forEach((child) => {
        if (
          child.nodeType === Node.TEXT_NODE &&
          child.textContent.trim() === ""
        ) {
          carousel.removeChild(child);
        }
      });
    }
  }, []);
  return (
    <div className="main">
      <div className="container">
        <div className="row">
          <div className="left ">
            <div className="left-content soft-black bg-white text-align-center">
              <img src={logo} alt="" />
              <h3 className="left-content-headline">Available Now</h3>
              <p className="left-content-paragraph1">
                Call us now For Your 100% Free Quote.
              </p>
              <a
                href="#"
                className="left-content-paragraph2 strong bg-red white"
              >
                <i className="icon icon-phone"></i> (855) 753-0142
              </a>
            </div>
          </div>
          <div className="right-content">
            <section className="right black">
              <div className="right-heading">
                <h2>Top Health Insurance Plans!</h2>
                <p>
                  Our process is very simple, get health insurance coverage in 3
                  easy steps (#2) 1,2,3 it can be numbered and or add icons next
                  to each number
                </p>
                <div class="check-list">
                  <div class="checkbox">
                    <div class="checkbox-img">
                      <i class="process"></i>
                    </div>
                    <div class="checkbox-content">
                      <h3>Step 1</h3>
                      <p>Call to speak with a licensed agent.</p>
                    </div>
                  </div>
                  <div class="checkbox">
                    <div class="checkbox-img">
                      <i class="process"></i>
                    </div>
                    <div class="checkbox-content">
                      <h3>Step 2</h3>
                      <p>
                        Answer a few easy questions and receive FREE financial
                        help.
                      </p>
                    </div>
                  </div>
                  <div class="checkbox">
                    <div class="checkbox-img">
                      <i class="process"></i>
                    </div>
                    <div class="checkbox-content">
                      <h3>Step 3</h3>
                      <p>Get covered as soon as tomorrow.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="paragraph">
                <hr className="hr" />
                <div className="paragraph-2">
                  <p className="first-p">
                    Want to talk to an expert? Our team is here for you and will
                    answer all your questions. Give us a call today on{" "}
                    <span className="strong red"> (844) 602-3502</span>
                  </p>
                  <img src={FamilyCareImg} />
                  <h2>What You Could Be Eligible For?</h2>
                  <p className=" normal-para">
                    Our licensed insurance agents are standing by waiting for
                    your call. Our certified agents will get you qualified for
                    maximum financial help, most people are paying $0 a month
                    and enjoying benefits such as:
                  </p>
                  <ul class="eligible">
                    <li>
                      <i class="icon icon-ok-circled"></i>
                      <strong>$0 </strong>Deductible
                    </li>
                    <li>
                      <i class="icon icon-ok-circled"></i>
                      <strong>$0 </strong>Copays
                    </li>
                    <li>
                      <i class="icon icon-ok-circled"></i>
                      <strong>$0 </strong>Preventive Care Visits
                    </li>
                    <li>
                      <i class="icon icon-ok-circled"></i>
                      <strong>$0 </strong>Doctor Visits
                    </li>
                  </ul>

                  <div class="section section-3">
                    <h2>Join Our Family Of Satisfied Customers</h2>
                    <div class="rating-companies">
                      <img src={logo1} alt="" />
                      <img src={logo2} alt="" />
                      <img src={logo3} alt="" />
                    </div>

                    <div class="testimonial-holder">
                      <OwlCarousel
                        items={1}
                        className="owl-theme"
                        loop
                        nav={false}
                        autoplay
                        autoplayTimeout={3000}
                      >
                        <div class="testimonial">
                          <p>
                            Jeff Morningstar, did a very good job explaining all
                            of my different options. Compared different plans,
                            taking into account the different doctor's I visit
                            and all the different options I use to let me decide
                            what plan that I wanted.
                          </p>
                          <div class="testimonial-bottom">
                            <div class="name-holder">Christie Morgan</div>
                            <div class="stars">
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                            </div>
                          </div>
                        </div>
                        <div class="testimonial">
                          <p>
                            Jeff Morningstar, did a very good job explaining all
                            of my different options. Compared different plans,
                            taking into account the different doctor's I visit
                            and all the different options I use to let me decide
                            what plan that I wanted.
                          </p>
                          <div class="testimonial-bottom">
                            <div class="name-holder">Christie Morgan</div>
                            <div class="stars">
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                            </div>
                          </div>
                        </div>
                        <div class="testimonial">
                          <p>
                            Jeff Morningstar, did a very good job explaining all
                            of my different options. Compared different plans,
                            taking into account the different doctor's I visit
                            and all the different options I use to let me decide
                            what plan that I wanted.
                          </p>
                          <div class="testimonial-bottom">
                            <div class="name-holder">Christie Morgan</div>
                            <div class="stars">
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                            </div>
                          </div>
                        </div>
                        <div class="testimonial">
                          <p>
                            Jeff Morningstar, did a very good job explaining all
                            of my different options. Compared different plans,
                            taking into account the different doctor's I visit
                            and all the different options I use to let me decide
                            what plan that I wanted.
                          </p>
                          <div class="testimonial-bottom">
                            <div class="name-holder">Christie Morgan</div>
                            <div class="stars">
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                              <i class="icon icon-star"></i>
                            </div>
                          </div>
                        </div>
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Right;
