import React from "react";
import "./Hero.scss";
import mainLogo from "../../assets/trustpilot.png";

const Hero = ({ state, city }) => {
  return (
    <div className="hero-section">
      <div className="container">
        <div className="row">
          <div className="mid">
            <div className="mid1 white text-align-center">
              <h1 className="strong ">
                New 2023 Special Discounts for American Residents Only
              </h1>
              <p>
                Find affordable health insurance now with plans starting as low
                as $0 month!
              </p>
              <h2 className="">Call Now For Your 100% Free Quote</h2>
              <a href="#" className="bg-red white">
                <i className="icon icon-phone">&#xe800;</i>(855) 753-0142
              </a>
            </div>

            <div class="rating">
              <img src={mainLogo} />
            </div>

            <div className="mid3 white text-align-center">
              <div className="star-holder">
                <div className="left-divider divider">
                  <hr />
                </div>
                <div className="h3">
                  <h3>You could be eligible for:</h3>
                </div>
                <div className="right-divider divider">
                  <hr />
                </div>
              </div>
            </div>

            <div className="feature-holder  white">
              <ul className="first">
                <li>
                  <div className="feature">
                    <div className="feature-icon">
                      <i className="icon icon-ok-circled"></i>
                    </div>
                    <div class="feature-tag">
                      <strong>$0</strong> Unlimited
                      <br /> primary care
                    </div>
                  </div>
                </li>
                <li>
                  <div className="feature">
                    <div className="feature-icon">
                      <i className="icon icon-ok-circled"></i>
                    </div>
                    <div class="feature-tag">
                      <strong>$0</strong> Unlimited virtual
                      <br /> urgent care
                    </div>
                  </div>
                </li>
              </ul>
              <ul className="second">
                <li>
                  <div className="feature">
                    <div className="feature-icon">
                      <i className="icon icon-ok-circled"></i>
                    </div>
                    <div class="feature-tag">
                      <strong>$3</strong> Prescriptions
                      <br /> at Walgreens
                    </div>
                  </div>
                </li>
                <li>
                  <div className="feature">
                    <div className="feature-icon">
                      <i className="icon icon-ok-circled"></i>
                    </div>
                    <div class="feature-tag">
                      <strong>$100</strong> Earn rewards
                      <br /> for health
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bg"></div>
    </div>
  );
};

export default Hero;
