import React from "react";
import "./Header.scss";
import Img from "../../assets/logo.png";

const Header = ({ city, state }) => {
  return (
    <nav className="bg-white shadow">
      <div className="container white">
        <div className="row">
          <div className="logo-header">
            <img src={Img} />
            <div className="black  ">{window.location.host[0].toUpperCase() + window.location.host.slice(1)}</div>
            {/* <p className="black  ">{city}, {state}</p> */}
          </div>
          <div className="right-header">
            <span className="black">Need Help?</span>
            <a className="red strong" href="#">
              <i className="icon icon-phone">&#xe800;</i> (844) 602-3502
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;