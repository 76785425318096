import React from "react";
import "./Cta.scss";

const Cta = ({ state, city }) => {
  return (
    <div className="cta-content strong white">
      <div className="container">
        <div className="row">
          <div className="mid text-align-center">
            <div className="mid1 text-align-center">
              <h1>Affordable Health Insurance</h1>
              <h3>Call Now For $0 Month Health Insurance</h3>
            </div>
            <div className="mid2">
              <a href="tel:(844) 602-3502">
                <i className="icon icon-phone"></i> (844) 602-3502
              </a>
            </div>
            <div className="mid3">
              <ul className="first">
                <li>
                  <div className="feature">
                    <div className="feature-icon">
                      <i className="icon icon-ok-circled">&#xe802;</i>
                    </div>
                    <div class="feature-tag">
                      <strong>$0</strong> Unlimited
                      <br /> primary care
                    </div>
                  </div>
                </li>
                <li>
                  <div className="feature">
                    <div className="feature-icon">
                      <i className="icon icon-ok-circled">&#xe802;</i>
                    </div>
                    <div class="feature-tag">
                      <strong>$0</strong> Unlimited virtual
                      <br /> urgent care
                    </div>
                  </div>
                </li>
                <li>
                  <div className="feature">
                    <div className="feature-icon">
                      <i className="icon icon-ok-circled">&#xe802;</i>
                    </div>
                    <div class="feature-tag">
                      <strong>$3</strong> Prescriptions
                      <br /> at Walgreens
                    </div>
                  </div>
                </li>
                <li>
                  <div className="feature">
                    <div className="feature-icon">
                      <i className="icon icon-ok-circled">&#xe802;</i>
                    </div>
                    <div class="feature-tag">
                      <strong>$100</strong> Earn rewards
                      <br /> for health
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bg"></div>
    </div>
  );
};

export default Cta;
