import "./App.scss";
import Cta from "./components/CTA/Cta";
import Footer from "./components/Footer/Footer";
import FlootingFooter from "./components/FlotingFooter/FlootingFooter";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import Right from "./components/Right/Right";
import React, { useEffect, useState } from "react";

function App() {
  const [cityStateResponse, setCityStateResponse] = useState({
    city: "",
    state: "",
  });

  const cityAddress = async () => {
    const options = {};
    const onSuccess = (success) => {
      const country = success.country
        ? success.country.names
          ? success.country.names.en
          : ""
        : "";
      const city = success.city
        ? success.city.names
          ? success.city.names.en
          : ""
        : "";
      // const state = success.subdivisions
      //   ? success.subdivisions[0]
      //     ? success.subdivisions[0].names.en
      //     : ""
      //   : "";

      const state = success.subdivisions
        ? success.subdivisions[0]
          ? success.subdivisions[0].iso_code
          : ""
        : "";

      console.log( success.subdivisions);
      setCityStateResponse({ state, city, country });
    };
    const onError = (error) => {
      console.log(error);
    };
    if (window.geoip2) await window.geoip2.city(onSuccess, onError, options);
  };

  useEffect(()=>{
    cityAddress()
  },[])
  return (
    <div className="bg-blue">
      <Header city={cityStateResponse.city} state={cityStateResponse.state} />
      <Hero city={cityStateResponse.city} state={cityStateResponse.state}  />
      <Right city={cityStateResponse.city} state={cityStateResponse.state}  />
      <Cta city={cityStateResponse.city} state={cityStateResponse.state}  />
      <Footer city={cityStateResponse.city} state={cityStateResponse.state}  />
      <FlootingFooter city={cityStateResponse.city} state={cityStateResponse.state}  />
    </div>
  );
}

export default App;
